import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useUIState from "../../hooks/useUIstate";
import {
  MdArrowBack,
  MdExitToApp,
  MdFastForward,
  MdRefresh,
  MdSettings,
  MdSwapVert,
  MdUpdate,
} from "react-icons/md";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import useDataState from "../../hooks/useDataState";
import JofemarSelectionPanel from "../../components/JofemarSelectionPanel";
import { Feature } from "flagged";
import { updateJofemarReqBody } from "../StoreSupport/StoreSupportController";
import { API } from "aws-amplify";
import ConfirmationModal from "../../components/ConfirmationModal";
import JofemarChannelConfigModal from "../../components/JofemarChannelConfigModal";

const useStyles = makeStyles((theme) => ({
  root: {
    "& h1, & h2": {
      marginTop: "5px",
      marginBottom: "5px",
    },
    "& #jofemarHeader": {
      background: "#eee",
      padding: "5px",
    },
    "& #jofemarHeader>*": {
      color: "#777",
      margin: 0,
    },
    "& #jofemarContents": {
      background: "#white",
    },
    "& #jofemarBody": {
      maxHeight: "70vh",
      overflowY: "scroll",
    },
    "& .jofemar-row": {
      padding: "5px",
      background: "#eaeaea",
    },
    "& .jofemar-columns": {
      background: "#eee",
    },
    "& .jofemar-row-header, & .jofemar-column-body": {
      background: "#fff",
      borderBottom: "1px solid #ddd",
    },
    "& .jofemar-column-body.selected": {
      background: "#e8f5e9",
    },
    "& .header-element": {
      padding: "5px",
      backgroundColor: "#689f38",
      color: "#ffffff",
      fontWeight: "bold",
      fontSize: "12pt",
      display: "flex",
      alignItems: "center",
    },
    "& .body-element": {
      padding: "5px",
      alignItems: "center",
      display: "flex",
      fontWeight: 700,
    },
    "& .product-image-container": {
      minWidth: "65px",
      height: "65px",
      background: "white",
      marginRight: "5px",
      border: "1px solid #ddd",
    },
    "& .product-image-container img": {
      width: "63px",
      height: "63px",
      objectFit: "fill",
    },
    "& .body-element.runtime-pusher .MuiTextField-root": {
      width: "50px",
      margin: "0 5px",
      background: "white",
    },
    "& .body-element.runtime-pusher .MuiButtonBase-root": {
      padding: "5px",
      minWidth: "40px",
    },
    "& .body-element.elevator-adjust .MuiTextField-root": {
      width: "50px",
      margin: "0 5px",
      background: "white",
    },
    "& .body-element.elevator-adjust .MuiButtonBase-root": {
      padding: "5px",
      minWidth: "40px",
    },
    "& .elevatorAdjustValue": {
      padding: "3px",
      minWidth: "70px",
    },
    "& .product-img": { width: "15%" },
    "& .current-stock": { width: "12.5%" },
    "& .max-capacity": { width: "12.5%" },
    "& .latest-refill": { width: "15%" },
    "& .is-enabled": { width: "10%" },
    "& .runtime-pusher": { width: "5%" },
    "& .elevator-adjust": { width: "5%" },
    "& .dispensing-speed": { width: "5%" },
    "& .button-row": { width: "20%" },
    "& .replenishment-bottom": {
      marginTop: "20px",
      maxWidth: "90vw",
    },
  },
}));

function StoreSupportJofemarScreen(props) {
  const history = useHistory();
  const classes = useStyles();

  const { ProductsContext, JofemarContext, StoreContext } = useDataState();
  const {
    setShowLoadingModal,
    setToastMessage,
    setShowToastMessage,
    setToastType,
  } = useUIState();

  const [Store, setStore] = useState(null);
  const [StoreId, setStoreId] = useState(null);
  const [Jofemar, setJofemar] = useState({ Attributes: "" });
  const [Jofemars, setJofemars]: [any, any] = useState([]);
  const [JofemarTitle, setJofemarTitle] = useState("");
  const [ActiveJofemarId, setActiveJofemarId] = useState(-1);
  const [JofemarInfo, setJofemarInfo]: [any, any] = useState([]);
  const [StoreTitle, setStoreTitle] = useState("");
  const [JofemarContents, setJofemarContents]: [any, any] = useState([]);
  const [Values, setValues]: [any, any] = useState([]);
  const [ShowPanel, setShowPanel] = useState(false);
  const [ActiveRow, setActiveRow] = useState(0);
  const [ActiveColumn, setActiveColumn] = useState(0);
  const [Products, setProducts]: [any, any] = useState([]);
  const [
    ShowProductDispensedConfirmationModal,
    setShowProductDispensedConfirmationModal,
  ]: [boolean, any] = useState(false);
  const [JofemarUpdateStockMachine, setJofemarUpdateStockMachine] =
    useState("");
  const [JofemarUpdateStockCode, setJofemarUpdateStockCode] = useState("");
  const [showConfigurationModal, setShowConfigurationModal]: [boolean, any] =
    useState(false);
  const [lineToConfigure, setLineToConfigure]: [any, any] = useState({});

  useEffect(() => {
    const value = localStorage.getItem("UserConfigData");
    console.log("props", props);
    if (props.location.Jofemar) {
      setStoreTitle(props.location.title);
      setJofemar(props.location.Jofemar);
      setJofemars(props.location.Jofemars);
      setActiveJofemarId(props.location.Jofemar.Id);
      setProducts(props.location.products);
      setStore(props.location.store);
      setStoreId(props.location.storeId);
    } else if (JofemarContext && Object.entries(JofemarContext).length) {
      // when is this scenario applicable?
      setJofemar(JofemarContext);
      setProducts(ProductsContext);
      setStore(StoreContext);
      setStoreId(StoreContext.Id);
    } else if (localStorage.getItem("Jofemar")) {
      // when is this scenario applicable?
      const JofemarLS = localStorage.getItem("Jofemar");
      if (JofemarLS) {
        setJofemar(JSON.parse(JofemarLS));
      }
      const Products = localStorage.getItem("Products");
      if (Products) {
        setProducts(JSON.parse(Products));
      }
      const Store = localStorage.getItem("ActiveStore");
      if (Store) {
        setStore(JSON.parse(Store));
        setStoreId(JSON.parse(Store).StoreId);
      }
    } else {
      history.push("/retail-locations");
    }
  }, []);

  useEffect(() => {
    if (ActiveJofemarId !== -1) {
      localStorage.setItem("ActiveJofemarId", String(ActiveJofemarId));
    }
  }, [ActiveJofemarId]);

  useEffect(() => {
    if (Jofemars) {
      const jofemarInfo: any[] = [];
      Jofemars.forEach((item) => {
        jofemarInfo.push({ id: item.Id, name: item.Code });
      });
      setJofemarInfo(jofemarInfo);
    }

    if (Jofemar) {
      let attrStr = "";
      const store = JSON.parse(JSON.stringify(Store));

      console.log("Jofemar.Attributes: " + Jofemar.Attributes);
      attrStr = Jofemar.Attributes;

      let attr = attrStr.split("|");
      const attributes: any[] = [];

      attr.forEach((el, index) => {
        let element = el;
        if (element) {
          attributes.push(JSON.parse(element.replace(/'/g, '"')));
        }
      });

      if (attributes.length > 0) {
        setJofemarTitle(attributes[0].vendingMachineLogicalName);
      }

      const values: any = [];
      attributes.forEach((row) => {
        const Columns: any = [];
        row.Columns.forEach((col) => {
          Columns.push({
            UpdateKeepRunningForMs: col.KeepRunningForMs,
            ElevatorAdjustMs: col.ElevatorAdjustMs,
            DispensingSpeed: col.DispensingSpeed,
          });
        });
        values.push({ Columns });
      });

      // updated from ls (persist from navigation)
      if (
        localStorage.getItem("CurrentJofemarContents") &&
        values.length &&
        attributes.length
      ) {
        const str = localStorage.getItem("CurrentJofemarContents") || "";
        const currJofemarContents = JSON.parse(str);
        currJofemarContents.forEach((row, rowIndex) => {
          row.Columns.forEach((col, colIndex) => {
            values[rowIndex].Columns[colIndex].UpdatedStock = col.UpdatedStock;
            attributes[rowIndex].Columns[colIndex].ProductId = col.ProductId;
            attributes[rowIndex].Columns[colIndex].LatestRefill =
              col.LatestRefill;
            attributes[rowIndex].Columns[colIndex].CurrentStock =
              col.CurrentStock;
            attributes[rowIndex].Columns[colIndex].Enabled = col.Enabled;
            attributes[rowIndex].Columns[colIndex].KeepRunningForMs =
              col.KeepRunningForMs;
            attributes[rowIndex].Columns[colIndex].ElevatorAdjustMs =
              col.ElevatorAdjustMs;
          });
        });
      }

      setValues(values);
      setJofemarContents(attributes);
    }
  }, [Jofemar, Jofemars]);

  function getDisplayDateTime(datetime) {
    //console.log(datetime)
    let date = datetime.split("T")[0];
    let time = datetime.split("T")[1];

    date = date.split("-");
    date = `${date[2]}/${date[1]}/${date[0]}`;

    time = time.split(":");
    time = `${time[0]}:${time[1]}`;

    return `${date} ${time}`;
  }

  async function activateJofemar(isTrue: boolean) {
    console.log("Activeer Jofemar: " + isTrue);

    const jofemarContents = [...JofemarContents];
    console.log("jofemarContents", jofemarContents);

    setShowLoadingModal(true);
    if (isTrue) {
      var vendingMachineName = jofemarContents[0].vendingMachineLogicalName;
      console.log(
        "ResetSoldOutChannels -> vendingMachineLogicalName: " +
          vendingMachineName
      );

      const body = { Name: vendingMachineName };
      console.log(body);

      var isReset = false;
      var url = `/Store/${StoreId}/VendingMachineResetSoldOutChannels`;
      await API.put("AE", url, { body })
        .then((res) => {
          console.log(
            `${url} VendingMachineName: ${res.VendingMachineName}, result: ${res.Success}`
          );
          isReset = res.Success;
        })
        .catch((error) => {
          console.log(`${url} result: ${error}`);
        })
        .finally(() => {});
      if (!isReset) {
        setToast(
          "error",
          "Reset van Jofemar uitverkocht status is niet gelukt",
          true
        );
        return;
      }
    }

    jofemarContents.forEach((row) => {
      row.Columns.forEach((col) => {
        col.Enabled = isTrue;
      });
    });

    console.log(jofemarContents);
    const reqBody = updateJofemarReqBody(Jofemar, jofemarContents);
    console.log(reqBody);

    const body = reqBody;

    API.put("AE", `/Blocks`, { body })
      .then((res) => {
        console.log("AE/Blocks put result: " + res);

        // save changes to CurrentJofemarContents
        localStorage.setItem(
          "CurrentJofemarContents",
          JSON.stringify(jofemarContents)
        );
        UpdateJofemarArray();
      })
      .catch((error) => {
        console.log(error);
        setToast("error", "De/activatie is niet gelukt", false);
      })
      .finally(() => {
        setToast(
          "success",
          isTrue ? "Activatie succesvol" : "Deactivatie succesvol",
          true
        );
      });
  }

  function setToast(type, message, hideModal) {
    setToastType(type);
    setToastMessage(message);
    setShowToastMessage(true);
    if (hideModal) {
      setShowLoadingModal(false);
    }
  }

  function updateJofemarStockAfterDispense(vendingMachineName, code) {
    const jofemarContents = [...JofemarContents];
    jofemarContents.forEach((row) => {
      if (row["vendingMachineLogicalName"] === vendingMachineName) {
        console.log("vendingmachine found");
        row.Columns.forEach((col) => {
          if (col["Code"] === code) {
            console.log("code found");
            // update Stock!
            if (col.CurrentStock > 0) {
              col.CurrentStock--;
            }
          }
        });
      }
    });

    const body = updateJofemarReqBody(Jofemar, jofemarContents);
    console.log(body);

    // save changes to AE/Blocks
    API.put("AE", `/Blocks`, { body })
      .then((res) => {
        console.log("AE/Blocks put result: " + res);

        // save changes to CurrentJofemarContents
        localStorage.setItem(
          "CurrentJofemarContents",
          JSON.stringify(jofemarContents)
        );
        UpdateJofemarArray();
      })
      .catch((error) => {
        console.log(error);
        setToastType("error");
        setToastMessage(
          "Blocks zijn niet geupdate, probleem met opslaan van CurrentStock"
        );
        setShowToastMessage(true);
      })
      .finally(() => {
        setToastType("success");
        setToastMessage(
          "Product is uitgegeven, controleer de tray van de vendingmachine of het product erin ligt."
        );
        setShowToastMessage(true);
        setShowLoadingModal(false);
      });
  }

  async function dispenseProduct(
    vendingMachineName,
    code,
    keepRunningForMs,
    elevatorAdjustMs,
    dispensingSpeed
  ) {
    console.log(
      "Dispense -> vendingMachineLogicalName: " +
        vendingMachineName +
        " Code: " +
        code
    );

    const body: any[] = [];
    body.push({
      vendingMachineName: vendingMachineName,
      code: code,
      keepRunningForMs: keepRunningForMs,
      elevatorAdjustMs: elevatorAdjustMs,
      DispensingSpeed: dispensingSpeed,
    });

    //const res = await API.put('localGateway', `/vendingmachine/Dispense`, { body });
    try {
      const res = await API.put(
        "AE",
        `/Store/${StoreId}/VendingMachineDispense`,
        { body }
      );
      console.log(res);

      //[{"VendingMachineName":"Vending 1","Code":"T18_C0","Success":false}]

      if (res.length > 0 && res[0].Success === true) {
        // update jofemarContents with new stockvalue
        updateJofemarStockAfterDispense(vendingMachineName, code);
      } else {
        askIfProductIsDispensed();
      }
    } catch (error) {
      askIfProductIsDispensed();
    }

    function askIfProductIsDispensed() {
      setJofemarUpdateStockMachine(vendingMachineName);
      setJofemarUpdateStockCode(code);
      setShowProductDispensedConfirmationModal(true);
      setToastType("error");
      setToastMessage(
        "Er is iets misgegaan, VendingMachineDispense resultaat is false (Product kan WEL zijn uitgegeven)"
      );
      setShowToastMessage(true);
    }
  }

  async function showConfiguration(
    rowIndex: number,
    colIndex: number,
    code: string,
    name: string
  ) {
    let row = Values[rowIndex];
    let col = row.Columns[colIndex];
    setLineToConfigure({
      row: row,
      col: col,
      code: code,
      name: name,
      jofemar: props.location.Jofemar,
    });
    setShowConfigurationModal(true);
  }

  async function closeModal() {
    setShowConfigurationModal(false);
  }

  const handleJofemarOnClick = (jofemarItem) => {
    loadJofemarById(jofemarItem.id);
  };

  handleJofemarOnClick.jofemarInfo = JofemarInfo;

  function loadJofemarById(id) {
    console.log("loading jofemar with id: " + id);
    // get from storage
    const jofemars = localStorage.getItem("Jofemars");

    if (jofemars) {
      localStorage.removeItem("CurrentJofemarContents");
      const jofemarsJson = JSON.parse(jofemars);
      console.log("number of jofemars found: " + jofemarsJson.length);
      const jofemar = jofemarsJson.find((block) => block.Id === id);
      let attrStr = "";
      attrStr = jofemar.Attributes;

      let attr = attrStr.split("|");
      const attributes: any[] = [];

      attr.forEach((el, index) => {
        let element = el;

        if (element) {
          attributes.push(JSON.parse(element.replace(/'/g, '"')));
        }
      });
      localStorage.setItem(
        "CurrentJofemarContents",
        JSON.stringify(attributes)
      );
      setJofemar(jofemar);
      localStorage.setItem("Jofemar", JSON.stringify(jofemar));
      setActiveJofemarId(id);
    }
  }

  function UpdateJofemarArray() {
    // update Jofemars array
    let index = 0;
    for (let i = 0; i < Jofemars.length; i++) {
      if (Jofemars[i].Id === ActiveJofemarId) {
        index = i;
        break;
      }
    }

    const jofemarContents = [...JofemarContents];
    const jofemars = Jofemars;
    const reqBody = updateJofemarReqBody(jofemars[index], jofemarContents);
    jofemars[index] = reqBody;
    localStorage.setItem("Jofemars", JSON.stringify(jofemars));
    setJofemars(jofemars);
  }

  function testConfigurationValues(value: any) {
    dispenseProduct(
      value.Name,
      value.Code,
      value.UpdateKeepRunningForMs,
      value.ElevatorAdjustMs,
      value.DispensingSpeed
    );
  }

  return (
    <div id="replenishmentJofemar" className={classes.root}>
      <div className="replenishment-header flex-center-align">
        <div style={{ cursor: "pointer" }}>
          <MdArrowBack size="30" onClick={() => history.goBack()} />
        </div>
        <h1 id="storeTitle" className="ml1">
          {StoreTitle}
        </h1>
        <h2 className="ml1">Support - Vending Machine</h2>
      </div>
      {JofemarContents ? (
        <div id="jofemarContents">
          <div id="jofemarHeader flex-center-align">
            <h4>Jofemar {JofemarTitle}</h4>
            <JofemarSelectionPanel
              activeId={ActiveJofemarId}
              jofemarInfo={JofemarInfo}
              passedFunction={handleJofemarOnClick}
            ></JofemarSelectionPanel>
            <br />
            <Button
              id="activateJofemar"
              type="button"
              variant="contained"
              className="green-btn"
              onClick={() => activateJofemar(true)}
            >
              Activeren
            </Button>
            &nbsp;
            <Button
              id="deactivateJofemar"
              type="button"
              variant="contained"
              className="green-btn"
              onClick={() => activateJofemar(false)}
            >
              Deactiveren
            </Button>
          </div>
          &nbsp;
          <div id="jofemarBody">
            {JofemarContents.map((entry, index) => {
              return (
                <div key={index} className="jofemar-row">
                  <div className="jofemar-row-header flex">
                    <div className="header-element product-img">
                      Rij #{index + 1}
                    </div>
                    <div className="header-element current-stock">
                      Huidige voorraad
                    </div>
                    <div className="header-element max-capacity">Max</div>
                    <div className="header-element latest-refill">
                      Laatst bijgevuld op
                    </div>
                    <div className="header-element is-enabled">Actief</div>
                    <div className="header-element button-row">Opties</div>
                    <Feature name="usejofemarconfigstage2">
                      <div className="header-element runtime-pusher">
                        <MdUpdate size={25} title="Runtime Adjust" />
                      </div>
                      <div className="header-element dispensing-speed">
                        <MdFastForward
                          size={25}
                          title="Dispense Speed Adjust"
                        />
                      </div>
                      <Feature name="adjustjofemarelevatorheight">
                        <div className="header-element elevator-adjust">
                          <MdSwapVert
                            size={25}
                            title="Elevator Height Adjust"
                          />
                        </div>
                      </Feature>
                    </Feature>
                  </div>

                  {entry.Columns.map((col, colIndex) => {
                    return (
                      <div
                        key={`${index}${colIndex}`}
                        id={`r${index}col${colIndex}`}
                        className={`jofemar-column-body flex ${
                          ActiveRow === index &&
                          ActiveColumn === colIndex &&
                          ShowPanel
                            ? "selected"
                            : ""
                        }`}
                      >
                        <div className="body-element product-img">
                          <div className="product-image-container">
                            {/* {col.ProductId && UserConfigData.Customer ?
                                                            <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${UserConfigData.Customer}/images/products/pid${col.ProductId}_${Products.find(prod => prod.Id === col.ProductId).Avatar}`} alt="prod-img" />
                                                            : null
                                                        } */}
                          </div>
                          <div>
                            {col.ProductId
                              ? Products.find((p) => p.Id === col.ProductId)
                                  .Code
                              : "Geen product"}
                          </div>
                        </div>
                        <div className="body-element current-stock">
                          {col.CurrentStock}
                        </div>
                        <div className="body-element max-capacity">
                          {entry.nrDepth}
                        </div>
                        <div className="body-element latest-refill">
                          {col.LatestRefill
                            ? getDisplayDateTime(col.LatestRefill)
                            : null}
                        </div>
                        <div className="body-element is-enabled">
                          {col.Enabled ? "Ja" : "Nee"}
                        </div>
                        <div className="body-element button-row">
                          <Button
                            variant="contained"
                            className="green-btn"
                            style={{ marginRight: "5px", width: "100px" }}
                            title="Product Uitgeven"
                            onClick={() =>
                              dispenseProduct(
                                entry.vendingMachineLogicalName,
                                col.Code,
                                Values[index].Columns[colIndex]
                                  .UpdateKeepRunningForMs,
                                Values[index].Columns[colIndex]
                                  .ElevatorAdjustMs,
                                Values[index].Columns[colIndex].DispensingSpeed
                              )
                            }
                          >
                            <MdExitToApp size={22} />
                          </Button>
                          <Feature name="usejofemarconfigstage2">
                            <Button
                              variant="contained"
                              className="green-btn"
                              title="Configureren"
                              style={{ width: "100px" }}
                              onClick={() =>
                                showConfiguration(
                                  index,
                                  colIndex,
                                  col.Code,
                                  entry.vendingMachineLogicalName
                                )
                              }
                            >
                              <MdSettings size={22} />
                            </Button>
                          </Feature>
                        </div>
                        <div className="body-element runtime-pusher">
                          {
                            Values[index].Columns[colIndex]
                              .UpdateKeepRunningForMs
                          }
                        </div>
                        <div className="body-element dispensing-speed">
                          {Values[index].Columns[colIndex].DispensingSpeed}
                        </div>
                        <div className="body-element elevator-adjust">
                          {Values[index].Columns[colIndex].ElevatorAdjustMs}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="replenishment-bottom flex jcsb">
            <div>
              <Button
                type="button"
                variant="contained"
                className="green-btn"
                onClick={() => history.goBack()}
              >
                Stap 1 - Lockers
              </Button>
            </div>
          </div>
        </div>
      ) : null}

      {ShowProductDispensedConfirmationModal ? (
        <ConfirmationModal
          message={"Is het product uitgegeven?"}
          confirmText={"JA"}
          cancelText={"NEE"}
          confirm={() => {
            updateJofemarStockAfterDispense(
              JofemarUpdateStockMachine,
              JofemarUpdateStockCode
            );
            setShowProductDispensedConfirmationModal(false);
          }}
          closeModal={() => setShowProductDispensedConfirmationModal(false)}
        />
      ) : null}

      {showConfigurationModal ? (
        <JofemarChannelConfigModal
          value={lineToConfigure}
          closeModal={() => closeModal()}
          testValues={testConfigurationValues}
        />
      ) : null}
    </div>
  );
}

export default StoreSupportJofemarScreen;
